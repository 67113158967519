import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, concatMap, Observable, ReplaySubject, tap } from 'rxjs';
import { MODAL_DATA, ModalRef } from '@dougs/ds';
import { PackageType } from '@dougs/ecommerce/dto';
import { EcommerceSaleStateService } from '@dougs/ecommerce/shared';
import { Operation } from '@dougs/operations/dto';

@Injectable()
export class SelectPackageTypeComponentModalService {
  private readonly isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isLoading$: Observable<boolean> = this.isLoading.asObservable();

  private readonly packageTypes: ReplaySubject<PackageType[]> = new ReplaySubject<PackageType[]>(1);
  packageTypes$: Observable<PackageType[]> = this.packageTypes.asObservable();

  private readonly refreshPackageTypesSubject: ReplaySubject<void> = new ReplaySubject<void>(1);
  refreshPackageTypes$: Observable<PackageType[]> = this.refreshPackageTypesSubject.asObservable().pipe(
    tap(() => this.isLoading.next(true)),
    concatMap(() => this.ecommerceSaleStateService.getPackageType(this.operation)),
    tap((packageTypes: PackageType[]) => this.packageTypes.next(packageTypes)),
    tap(() => this.isLoading.next(false)),
  );

  constructor(
    @Inject(MODAL_DATA)
    public operation: Operation,
    private readonly ecommerceSaleStateService: EcommerceSaleStateService,
    private readonly modalRef: ModalRef,
  ) {}

  refreshPackageTypes(): void {
    this.refreshPackageTypesSubject.next();
  }

  selectPackage(packageType: PackageType): void {
    this.modalRef.close({
      packageType,
    });
  }
}
