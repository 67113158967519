import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  LoaderComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalTitleDirective,
  TrackByPipe,
} from '@dougs/ds';
import { OperationEcommerceComponentService } from '../../services/operation-ecommerce.component.service';
import { SelectPackageTypeComponentModalService } from '../../services/select-package-type-component-modal.component.service';

@Component({
  selector: 'dougs-select-package-type-modal',
  templateUrl: './select-package-type-component-modal.component.html',
  styleUrls: ['./select-package-type-component-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    CommonModule,
    TrackByPipe,
    LoaderComponent,
  ],
  providers: [SelectPackageTypeComponentModalService, OperationEcommerceComponentService],
})
export class SelectPackageTypeModalComponent implements OnInit {
  hasIossAndIsMarketPlace = false;
  constructor(
    public readonly selectPackageTypeComponentModalService: SelectPackageTypeComponentModalService,
    private readonly operationEcommerceComponentService: OperationEcommerceComponentService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.selectPackageTypeComponentModalService.refreshPackageTypes();
    this.hasIossAndIsMarketPlace = await this.operationEcommerceComponentService.hasIossAndIsMarketPlace(
      this.selectPackageTypeComponentModalService.operation,
    );
  }
}
