<div dougsModalTitle>
  <h6>Vos ventes ont été réalisées à partir de :</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <ng-container *ngIf="selectPackageTypeComponentModalService.refreshPackageTypes$ | async"></ng-container>
  <ng-container *ngIf="!(selectPackageTypeComponentModalService.isLoading$ | async); else loader">
    <div *ngIf="(selectPackageTypeComponentModalService.packageTypes$ | async)?.length">
      <div
        (click)="selectPackageTypeComponentModalService.selectPackage(packageType)"
        class="package-line pointer py-8"
        *ngFor="
          let packageType of selectPackageTypeComponentModalService.packageTypes$ | async;
          trackBy: 'id' | trackBy
        "
      >
        <div class="package-line__label">
          <img *ngIf="packageType.flag" class="mr-8" src="images/flags/{{ packageType.flag }}.svg" />
          <p [innerHTML]="packageType.label" class="small color-primary-700"></p>
        </div>
        <i
          *ngIf="hasIossAndIsMarketPlace && packageType.packageId === 'over150'"
          class="fal fa-chevron-right color-primary-700 mr-4"
        ></i>
      </div>
    </div>
  </ng-container>
  <ng-template #loader>
    <dougs-loader></dougs-loader>
  </ng-template>
</div>
